import React from "react"
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
// Animate on scroll
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

// import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import NEWSLETTER from "../components/signUpNewletter"


const IndexPage = props => (
  <Layout>
    <SEO title="Analytics and Data Science on-demand" />
    <div className={"container"}>
      <div className={"row"}>
        <div className={"col"}>
          <h1 className={"section-header"}>Get and understand the data you need</h1>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-6"}>
          <div className={"section"}>
            <h3>Best in class data team.  At a fixed price. No surprises.</h3>
            <p>
              We provide the Analytics and Data Science team you need in order to get and interpret your business data. With a detailed timeline and commitment to your success. We know what a startup needs to be operate and deliver. Get better data, drive your marketing efforts, get insights on your product roadmap, validate your assumptions, you name it.
            </p>
            <a href="/packages" className={"btn btn-primary shadow"} role="button" aria-pressed="true" id="btn-hme-intro">Pick a package</a>
          </div>
        </div>
        <div className={"col"}>

        </div>
      </div>
      {/* Intro */}
      <ScrollAnimation animateIn="fadeIn" animateOnce="true">
        <div className={"row"}>
          <div className={"col"}>
            <h2 className={"section-header"}>Analytics and data-science on-demand</h2>
          </div>

        </div>
        <div className={"row"}>
          <div className={"col-md-6"}>
            <div className={"section"}>
              <h3>How we can help you</h3>
              <p>
                We help you take strategic decisions around your data and implement a solid data strategy for every stage of your business. From Google Analytics to Amplitude or Hotspot. We know what are the data needs of 99% of the companies and how important is to set it right.
                <br/><br/>
                We bring to the table:
              </p>
              {/* List of value provided through our data interventions */}
              <div className={"value-container"}>
                <p className={"value"}>
                  <strong>Alignment for your team around solid Goals and KPIs</strong><br/>
                  We help you identify and select the right metrics to inform your business decisions
                </p>
                <p className={"value"}>
                  <strong>Trust in your data stack</strong><br/>
                  With the right instrumentalization and data audit, you will be confident on your direction. We help you chose a tool set to collect data for your stage and configure it for you, avoiding usual pitfalls and headaches down the road.
                </p>
                <p className={"value"}>
                  <strong>Understanding of your acquisition funnel</strong><br/>
                  We guide you to collect data to have the whole picture and to understand what is the funnel for your product, what is the user lifecycle and how it impacts your business
                </p>
                <p className={"value"}>
                  <strong>We make every $ count</strong><br/>
                  Define the attribution model that works best for you and be 100% sure you put your money where it should be
                </p>
                <p className={"value"}>
                  <strong>Insights to drive your product roadmap</strong><br/>
                  Data can help you understand the impact your changes in the product drives. It can help you testing and deploying different features, identifying the biggest value for your customers or improve the usability of your product
                </p>
                {/* List of value provided through our data interventions */}
              </div>

            </div>
          </div>
          <div className={"col"}>

          </div>
        </div>
      </ScrollAnimation>
      {/* How does it work */}
      <ScrollAnimation animateIn="fadeIn" animateOnce="true">
      <div className={"row"}>
        <div className={"col"}>
          <h2 className={"section-header"}>How does it work</h2>
        </div>
      </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn" animateOnce="true">
      <div className={"row"}>
        <div className={"col-md-6"}>
          <div className={"section"}>
            <h3>It all starts with understanding your business</h3>
            <p>If you have a clear idea of what are your data needs, check what package fits your needs. Our packages are designed to serve the data needs of most projects, at different stages. You pick your package and we assign you an analyst that will start evaluating if that is the right package for you and working with you straight away</p>
            <p>
              <img src="images/A_symbol.svg" alt="" width={"36px"} className={"img-symbol"} />
               You book a package according to your needs or book a Free consultation
            </p>
            <p>
              <img src="images/B_symbol.svg" alt="" width={"36px"} className={"img-symbol"} />
              Your company gets assigned a senior Analyst that starts working with you
            </p>
            <p>
              <img src="images/C_symbol.svg" alt="" width={"36px"} className={"img-symbol"} />
              There is an upfront timeline and clear deliverables during the whole process
            </p>
            <p>
              <img src="images/D_symbol.svg" alt="" width={"36px"} className={"img-symbol"} />
              Once  complete, you got timely insights, reports and analysis of your data
            </p>

            <a href="/packages" className={"btn btn-primary"} role="button" aria-pressed="true">Pick a package</a>
          </div>
          <div className={"section"}>
            <h3>Get your FREE data strategy session</h3>
            <p>If your business generate over 50000 MRR, you can pick one of our analysts’ brain to talk with for free and help you evaluate what are the exact requirements and goals of your mission.
              He or she, will discuss what are your data needs, what options and strategies to apply and suggest what should be your ideal roadmap.
              (others charge up to $400 for this)will help you identify what package would work best for you, or if you need a custom project just for you. We love to give data advice!</p>
            <button type="button" className={"btn btn-primary"} id={"btn_schedule_home"}>Schedule your FREE consultation</button>
          </div>
        </div>
        <div className={"col"}></div>
      </div>
      </ScrollAnimation>
      {/* Why Us*/}
      <ScrollAnimation animateIn="fadeIn" animateOnce="true">
      <div className={"row"}>
        <div className={"col"}>
          <h2 className={"section-header"}>Who we are, and why we can help</h2>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-md-6"}>
          <div className={"section"}>
            <h3>We have seen it all. Well, almost.</h3>
            <p>
              <strong>Clients in 11 countries and counting. 3 different continents. 6 languages.</strong><br/>
              We have worked with several Forbes 100 companies, dozens of startups and NGOs.  We know what data or insights companies need to operate and improve their performance. If you have an issue it is more than probable that we have already seen it. We know it is a jungle out there. We help you make strategic decisions around your data and implement a solid data strategy for every stage of your business.
            </p>
            <p>
              <strong>Our team's results</strong><br/>
              have built analytic systems, provided insights or training for these companies.
              We can do the same for you.
            </p>
            <Img fluid={props.data.imageOne.childImageSharp.fluid} alt={"We have worked at/for all these companies"}/>
            <p>
              <strong>Our analysts</strong><br/>
              Our average profile. Over 5 years experience, at least one big Tech company in the background, some of us have even founded startups, Certified, with a strong and updated understanding of the technology (we invest into that), good communicators, even better storytellers (we invest into that as well).
              Nice, committed people you’d love to work with.
            </p>

            <p>
              <strong>We work with clients all around the world</strong><br/>
              We speak your language. Our team is operating in three different continents and will definitely be able to cover your time zone. If needed, we will hire locally to provide the extra support and understanding of your needs.</p>
          </div>
        </div>
        <div className={"col"}></div>
      </div>
      </ScrollAnimation>
      {/* Newsletter */}
      <div className={"row"}>
        <div className={"column"}>
          {/*<NEWSLETTER />*/}
          {/*<Link to="/posts/">Moore articles on ...</Link>*/}
        </div>
      </div>

    </div>
  </Layout>
)

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const fluidTracedImage = graphql`
  fragment fluidTracedImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "list-of-companies.png" }) {
      ...fluidTracedImage
    }
    symbolA: file(relativePath: { eq: "C_symbol.png" }) {
      ...fluidTracedImage
    }
  }
`;